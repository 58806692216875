import React from "react";
import chartImg from "../../assets/img/images/chart.png";
import SalesTabButton from "./SalesTabButton";
import SalesTabContent from "./SalesTabContent";

const Sales = () => {
  const chart_info_list = [
    "Contingency: 70%",
    "Business Development: 10%",
    "Investor: 30%",
    "Poland",
    "Legal & Regulation:10%",
    "Czech Republic",
  ];

  return (
    <section id="sales" className="chart-area chart-bg ">
      <div className="container">
        <div className="chart-inner">
          <div className="row align-items-center justify-content-center">
            {/*<div className="col-lg-6 col-md-10 order-0 order-lg-2">
              <div className="chart-wrap wow fadeInRight" data-wow-delay=".2s">
                <img src={chartImg} alt="" />
                <ul>
                  {chart_info_list.map((x, index) => (
                    <li key={index}>{x}</li>
                  ))}
                </ul>
              </div>
                  </div>*/}

            <div className="col-lg-12 col-md-10">
              <div
                className="chart-content wow fadeInLeft"
                data-wow-delay=".2s"
              >
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <SalesTabButton
                    title="Security and reliability"
                    className="active"
                    id="security-tab"
                    target="#security"
                    ariaControls="security"
                    ariaSelected={true}
                  />

                  <SalesTabButton
                    title="Powerful hardware"
                    className=""
                    id="hardware-tab"
                    target="#hardware"
                    ariaControls="hardware"
                    ariaSelected={false}
                  />
                  <SalesTabButton
                    title="Experienced and knowledgeable"
                    className=""
                    id="experienced-tab"
                    target="#experienced"
                    ariaControls="experienced"
                    ariaSelected={false}
                  />
                </ul>

                <div className="tab-content" id="myTabContent">
                  <SalesTabContent
                    className={"show active"}
                    id="security"
                    ariaLabel="security-tab"
                    title="Security and reliability"
                    description="We understand the importance of maintaining the security and reliability of the blockchain, and we are committed to doing our part to ensure that blockchain networks are trustworthy and secure. With our expertise and capabilities, we are confident in our ability to provide top-quality validation services for blockchain networks of all kinds."
                    link="/"
                  />

                  <SalesTabContent
                    className={""}
                    id="hardware"
                    ariaLabel="hardware-tab"
                    title="Powerful hardware"
                    description="Our validators use advanced algorithms and powerful hardware to process transactions quickly and accurately. We work around the clock to ensure that operations are validated in a timely and efficient manner, providing users with fast and reliable processing."
                    link="/"
                  />
                  <SalesTabContent
                    className={""}
                    id="experienced"
                    ariaLabel="experienced-tab"
                    title="Experienced and knowledgeable"
                    description="We are a team of experienced and knowledgeable individuals dedicated to validating transactions on the blockchain. Our group is composed of skilled validators who are committed to ensuring the security and reliability of blockchain networks."
                    link="/"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Sales;
