import React from "react";
import img01 from "../../assets/img/images/about_img01.png";
import img02 from "../../assets/img/images/about_img02.png";
import { Link } from "react-router-dom";

const WhoWeAre = (props) => {
  return (
    <section id="about" className="about-area pt-130 pb-130">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-img wow fadeInLeft" data-wow-delay=".2s">
              <img src={img01} alt="" />
              <img src={img02} alt="" className="img-two" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content wow fadeInRight" data-wow-delay=".2s">
              <div className="section-title mb-30">
                <span className="sub-title">Who we are</span>
                <h2 className="title">
                  Seasoned and skilled <span>validators</span>
                </h2>
              </div>
              <p>
              We are a team of experienced and knowledgeable individuals dedicated to validating transactions on the blockchain. Our group is composed of skilled validators who are committed to ensuring the security and reliability of blockchain networks.
              </p>
              {/*<Link to="/" className="btn">
                Purchase Tokens
  </Link>*/}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhoWeAre;
