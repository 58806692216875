import React from "react";
import img01 from "../../assets/img/partner/partner_img01.png";
import img02 from "../../assets/img/partner/partner_img02.png";
import img03 from "../../assets/img/partner/partner_img03.png";
import img04 from "../../assets/img/partner/partner_img04.png";
import img05 from "../../assets/img/partner/partner_img05.png";
import img06 from "../../assets/img/partner/partner_img06.png";
import img07 from "../../assets/img/partner/partner_img07.png";
import img08 from "../../assets/img/partner/partner_img08.png";
import img09 from "../../assets/img/partner/partner_img09.png";
import img10 from "../../assets/img/partner/partner_img10.png";

const TopPartners = () => {
  const partners_list = [
    { src: img01, title: "0L", explorer: "https://0l.fyi/accounts/0D785EB2412263BEB0DBBB6468F35A1A" },
    { src: img02, title: "Orai", explorer: "https://scan.orai.io/validators/oraivaloper1aaqxxpqpz6un52pv4gxf6v39s4lcfdnlxnfnhs" },
    { src: img03, title: "Energi", explorer: "https://explorer.energi.network/address/0x2608017C81227cA3Be9ac4e4F36650F486dd1853" },
    { src: img04, title: "Muon", explorer: null },
    { src: img05, title: "Avail", explorer: "https://explorer.avail.so/#/staking" },
    { src: img06, title: "Mintlayer", explorer: "http://mintlayerstats.com:3000/pool/mpool1l6vksuza0ua5xst8qq2ztdek6e2quwv5822gtz5n6d7gv7uqaj7s8d0dug" },
    { src: img07, title: "Redbelly", explorer: null },
    { src: img08, title: "Initia", explorer: "https://scan.testnet.initia.xyz/initiation-1/validators/initvaloper1ajhvag6yejt8a2ns0sjrq9ftm05ej9yrmfl5kk" },
    { src: img09, title: "Lamina1", explorer: null },
    { src: img10, title: "XAI", explorer: "https://app.xai.games/pool/0x160D5Ee70F5979018B4eD4982097aBFCb0b8cc43/summary" },
  ];

  return (
    <div id="track-record" className="partner-area pb-130">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title text-center mb-10">
              <span className="sub-title">Track record</span>
              <h2 className="title">
              A selection of our active <span>validators</span>
              </h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="partner-wrap">
              <ul>
                {partners_list.map((item, index) => {
                  if (item.explorer) {
                  return (
                    <li key={index}>
                      <img src={item.src} alt={item.title} class="partnerImage" />
                      <a href={item.explorer} target="_blank" rel="noreferrer" class="mt-auto">Explorer</a>
                    </li>
                  )
                }
                else {
                  return (
                    <li key={index}>
                      <img src={item.src} alt={item.title} class="partnerImage" />
                      <span class="mt-auto"></span>
                    </li>
                  )
                }
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopPartners;
